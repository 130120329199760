import Contact_1 from './Contact_1'
import Contact_2 from './Contact_2'
import Contact_3 from './Contact_3'
import Contact_4 from './Contact_4'
import Contact_5 from './Contact_5'
import Contact_6 from './Contact_6'
import Contact_7 from './Contact_7'
import Contact_8 from './Contact_8'
import Contact_9 from './Contact_9'

export default {
  Contact_1: Contact_1,
  Contact_2: Contact_2,
  Contact_3: Contact_3,
  Contact_4: Contact_4,
  Contact_5: Contact_5,
  Contact_6: Contact_6,
  Contact_7: Contact_7,
  Contact_8: Contact_8,
  Contact_9: Contact_9
}
