import Feature1 from './Feature_1'
import Feature2 from './Feature_2'
import Feature3 from './Feature_3'
import Feature4 from './Feature_4'
import Feature5 from './Feature_5'
import Feature6 from './Feature_6'
import Feature7 from './Feature_7'
import Feature8 from './Feature_8'
import Feature9 from './Feature_9'
import Feature10 from './Feature_10'
import Feature11 from './Feature_11'
import Feature12 from './Feature_12'
import Feature13 from './Feature_13'
import Feature14 from './Feature_14'
import Feature15 from './Feature_15'
import Feature16 from './Feature_16'
import Feature17 from './Feature_17'
import Feature18 from './Feature_18'
import Feature19 from './Feature_19'
import Feature20 from './Feature_20'
import Feature21 from './Feature_21'
import Feature22 from './Feature_22'
import Feature23 from './Feature_23'
import Feature24 from './Feature_24'
import Feature25 from './Feature_25'
import Feature26 from './Feature_26'
import Feature27 from './Feature_27'

export default {
  Feature_1: Feature1,
  Feature_2: Feature2,
  Feature_3: Feature3,
  Feature_4: Feature4,
  Feature_5: Feature5,
  Feature_6: Feature6,
  Feature_7: Feature7,
  Feature_8: Feature8,
  Feature_9: Feature9,
  Feature_10: Feature10,
  Feature_11: Feature11,
  Feature_12: Feature12,
  Feature_13: Feature13,
  Feature_14: Feature14,
  Feature_15: Feature15,
  Feature_16: Feature16,
  Feature_17: Feature17,
  Feature_18: Feature18,
  Feature_19: Feature19,
  Feature_20: Feature20,
  Feature_21: Feature21,
  Feature_22: Feature22,
  Feature_23: Feature23,
  Feature_24: Feature24,
  Feature_25: Feature25,
  Feature_26: Feature26,
  Feature_27: Feature27
}
