var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor-page",class:{
          'editor-page-preview-mode': _vm.previewMode,
          'editor-page-mobile-preview-mode': _vm.mobilePreviewMode,
          'editor-page-tablet-preview-mode':_vm.tabletPreviewMode
          }},[_c('PageHeader'),_c('Preview',{directives:[{name:"show",rawName:"v-show",value:(_vm.previewMode),expression:"previewMode"}],ref:"preview",attrs:{"preview-version":_vm.previewVersion}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.previewMode),expression:"!previewMode"}],staticClass:"wrapper"},[(_vm.pageSideBarIsActive)?_c('nav',{staticClass:"editable-part-sidebar w-25",class:{'active': !_vm.pageSideBarIsActive},attrs:{"id":"sidebar"}},[_c('b-button-group',{staticClass:"w-100 mb-3",attrs:{"size":"sm"}},[_c('b-dropdown',{staticClass:"w-100",attrs:{"text":_vm.$t(("widgets." + (_vm.groupedWidgetList[_vm.currentListIndex].title))),"variant":"outline-primary"}},_vm._l((_vm.groupedWidgetList),function(item,index){return _c('b-dropdown-item',{key:index,attrs:{"value":"index"},on:{"click":function($event){_vm.currentListIndex = index}}},[_vm._v(" "+_vm._s(_vm.$t(("widgets." + (item.title))))+" ")])}),1)],1),_vm._l((_vm.groupedWidgetList),function(item,index){return _c('div',{key:index},[(_vm.delayedSidebarPreview && index == _vm.currentListIndex)?_c('WidgetList',{attrs:{"widget-list":item.widgets}}):_vm._e()],1)})],2):_vm._e(),_c('div',{staticClass:"plotarea float-right mr-0",class:{
               'plotarea-width-sidebar': _vm.pageSideBarIsActive,
               'w-100': !_vm.pageSideBarIsActive,
               'w-75': _vm.pageSideBarIsActive
               }},[_c('draggable',{attrs:{"options":{handle:'.widget-block--toolbox'},"group":"pagewidget"},model:{value:(_vm.currentWidgetList),callback:function ($$v) {_vm.currentWidgetList=$$v},expression:"currentWidgetList"}},_vm._l((_vm.currentWidgetList),function(widget){return _c('div',{key:widget.uniqeId},[_c(widget.name,{tag:"component",staticClass:"widget-block",class:{'container': (widget.data && widget.data.config) ?
                   !widget.data.config.fullWidth : false },attrs:{"edit-mode":!_vm.$store.state.main.previewMode,"widgetData":widget.data,"uniqeKey":widget.uniqeId}})],1)}),0),(_vm.currentWidgetList.length == 0)?_c('div',{staticClass:"m-auto"},[_c('h5',{staticClass:"text-center mt-5"},[_vm._v(_vm._s(_vm.$t('messages.start_with_widgets')))]),_c('AddWidget')],1):_c('div',[_c('AddWidget')],1)],1),_c('Modal'),_c('ModalWidgetList'),_c('ModalSettings'),_c('ModalImport')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }