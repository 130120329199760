export default {
  title: 'Content',
  group: 'content',
  widgets: [
    {
      name: 'Content_1'
    },
    {
      name: 'Content_2'
    },
    {
      name: 'Content_3'
    },
    {
      name: 'Content_4'
    },
    {
      name: 'Content_5'
    },
    {
      name: 'Content_6'
    },
    {
      name: 'Content_7'
    },
    {
      name: 'Content_8'
    },
    {
      name: 'Content_9'
    },
    {
      name: 'Content_10'
    },
    {
      name: 'Content_11'
    },
    {
      name: 'Content_12'
    },
    {
      name: 'Content_13'
    },
    {
      name: 'Content_14'
    },
    {
      name: 'Content_15'
    },
    {
      name: 'Content_16'
    },
    {
      name: 'Content_17'
    },
    {
      name: 'Content_18'
    },
    {
      name: 'Content_19'
    },
    {
      name: 'Content_20'
    },
    {
      name: 'Content_21'
    },
    {
      name: 'Content_22'
    },
    {
      name: 'Content_23'
    },
    {
      name: 'Content_24'
    },
    {
      name: 'Content_25'
    },
    {
      name: 'Content_26'
    },
    {
      name: 'Content_27'
    },
    {
      name: 'Content_28'
    }
  ]
}
