export default {
  title: 'Contact',
  group: 'contact',
  widgets: [
    {
      name: 'Contact_1',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_2',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_3',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_4',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_5',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_6',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_7',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_8',
      group: 'contact',
      data: {}
    },
    {
      name: 'Contact_9',
      group: 'contact',
      data: {}
    }
  ]
}
