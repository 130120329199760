import Content1 from './Content_1'
import Content2 from './Content_2'
import Content3 from './Content_3'
import Content4 from './Content_4'
import Content5 from './Content_5'
import Content6 from './Content_6'
import Content7 from './Content_7'
import Content8 from './Content_8'
import Content9 from './Content_9'
import Content10 from './Content_10'
import Content11 from './Content_11'
import Content12 from './Content_12'
import Content13 from './Content_13'
import Content14 from './Content_14'
import Content15 from './Content_15'
import Content16 from './Content_16'
import Content17 from './Content_17'
import Content18 from './Content_18'
import Content19 from './Content_19'
import Content20 from './Content_20'
import Content21 from './Content_21'
import Content22 from './Content_22'
import Content23 from './Content_23'
import Content24 from './Content_24'
import Content25 from './Content_25'
import Content26 from './Content_26'
import Content27 from './Content_27'
import Content28 from './Content_28'

export default {
  Content_1: Content1,
  Content_2: Content2,
  Content_3: Content3,
  Content_4: Content4,
  Content_5: Content5,
  Content_6: Content6,
  Content_7: Content7,
  Content_8: Content8,
  Content_9: Content9,
  Content_10: Content10,
  Content_11: Content11,
  Content_12: Content12,
  Content_13: Content13,
  Content_14: Content14,
  Content_15: Content15,
  Content_16: Content16,
  Content_17: Content17,
  Content_18: Content18,
  Content_19: Content19,
  Content_20: Content20,
  Content_21: Content21,
  Content_22: Content22,
  Content_23: Content23,
  Content_24: Content24,
  Content_25: Content25,
  Content_26: Content26,
  Content_27: Content27,
  Content_28: Content28
}
