import Header1 from './Header_1'
import Header2 from './Header_2'
import Header3 from './Header_3'
import Header4 from './Header_4'
import Header5 from './Header_5'
import Header6 from './Header_6'
import Header7 from './Header_7'
import Header8 from './Header_8'
import Header9 from './Header_9'
import Header10 from './Header_10'
import Header11 from './Header_11'
import Header12 from './Header_12'
import Header13 from './Header_13'
import Header14 from './Header_14'

export default {
  Header_1: Header1,
  Header_2: Header2,
  Header_3: Header3,
  Header_4: Header4,
  Header_5: Header5,
  Header_6: Header6,
  Header_7: Header7,
  Header_8: Header8,
  Header_9: Header9,
  Header_10: Header10,
  Header_11: Header11,
  Header_12: Header12,
  Header_13: Header13,
  Header_14: Header14
}
