export default {
  title: 'Call to Action',
  group: 'calltoaction',
  widgets: [
    {
      name: 'CallToAction_1'
    },
    {
      name: 'CallToAction_2'
    },
    {
      name: 'CallToAction_3'
    },
    {
      name: 'CallToAction_4'
    },
    {
      name: 'CallToAction_5'
    },
    {
      name: 'CallToAction_6'
    },
    {
      name: 'CallToAction_7'
    },
    {
      name: 'CallToAction_8'
    },
    {
      name: 'CallToAction_9'
    },
    {
      name: 'CallToAction_10'
    },
    {
      name: 'CallToAction_11'
    },
    {
      name: 'CallToAction_12'
    },
    {
      name: 'CallToAction_13'
    },
    {
      name: 'CallToAction_14'
    },
    {
      name: 'CallToAction_15'
    },
    {
      name: 'CallToAction_16'
    },
    {
      name: 'CallToAction_17'
    },
    {
      name: 'CallToAction_18'
    },
    {
      name: 'CallToAction_19'
    }
  ]
}
