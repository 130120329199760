import CallToAction_1 from './CallToAction_1'
import CallToAction_2 from './CallToAction_2'
import CallToAction_3 from './CallToAction_3'
import CallToAction_4 from './CallToAction_4'
import CallToAction_5 from './CallToAction_5'
import CallToAction_6 from './CallToAction_6'
import CallToAction_7 from './CallToAction_7'
import CallToAction_8 from './CallToAction_8'
import CallToAction_9 from './CallToAction_9'
import CallToAction_10 from './CallToAction_10'
import CallToAction_11 from './CallToAction_11'
import CallToAction_12 from './CallToAction_12'
import CallToAction_13 from './CallToAction_13'
import CallToAction_14 from './CallToAction_14'
import CallToAction_15 from './CallToAction_15'
import CallToAction_16 from './CallToAction_16'
import CallToAction_17 from './CallToAction_17'
import CallToAction_18 from './CallToAction_18'
import CallToAction_19 from './CallToAction_19'

export default {
  CallToAction_1: CallToAction_1,
  CallToAction_2: CallToAction_2,
  CallToAction_3: CallToAction_3,
  CallToAction_4: CallToAction_4,
  CallToAction_5: CallToAction_5,
  CallToAction_6: CallToAction_6,
  CallToAction_7: CallToAction_7,
  CallToAction_8: CallToAction_8,
  CallToAction_9: CallToAction_9,
  CallToAction_10: CallToAction_10,
  CallToAction_11: CallToAction_11,
  CallToAction_12: CallToAction_12,
  CallToAction_13: CallToAction_13,
  CallToAction_14: CallToAction_14,
  CallToAction_15: CallToAction_15,
  CallToAction_16: CallToAction_16,
  CallToAction_17: CallToAction_17,
  CallToAction_18: CallToAction_18,
  CallToAction_19: CallToAction_19
}
