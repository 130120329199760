export default {
  title: 'Advanced',
  group: 'advanced',
  widgets: [
    {
      name: 'Iframe'
    }
    // {
    //  name: 'Slider'
    // }
  ]
}
