var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-columns"},[_c('draggable',{attrs:{"sort":false,"clone":_vm.cloneDog,"ghost-class":"ghost-ghost","drag-class":"drag-ghost","chosen-class":"chosen-ghost","list":_vm.widgetList,"group":{ name: 'pagewidget', pull: 'clone', put: false }}},_vm._l((_vm.widgetList),function(widget,index){return (_vm.widgetList && _vm.widgetList.length > 0)?_c('div',{key:index,staticClass:"card mt-1"},[_c('div',{ref:"column",refInFor:true,staticClass:"add-widget-modal--widget-item mb-0",class:{
               'add-widget-modal--widget-item--selected': _vm.addWidgetList.find(function (e) {return e.name === widget.name})
               },style:({
               height: _vm.heightList[index] ? ((_vm.heightList[index] * _vm.sanitizedScale) + "px")  : "100px"
               }),on:{"click":function($event){return _vm.updateAddList(widget, _vm.$refs.widegtContainer[index])}}},[_c('Frame',{staticClass:"widget-thumb-container",style:({
                 width: (_vm.widthFrame + "px"),
                 transform: ("scale(" + _vm.sanitizedScale + ")"),
                 height: _vm.heightList[index] ? ((_vm.heightList[index]) + "px")  : "100px"
                 })},[_c('FrameChild',[_c(widget.name,{ref:"widegtContainer",refInFor:true,tag:"component",staticStyle:{"overflow":"hidden"},attrs:{"demoMode":true,"uniqeKey":widget.uniqeId}})],1)],1),(_vm.overlayVisbility)?_c('div',{staticClass:"iframe-loading-overlay"},[_c('b-spinner',{staticClass:"m-5 mt-5",attrs:{"variant":"primary","size":"sm","label":"Spinning"}})],1):_vm._e(),(_vm.getWidgetIndex(widget.name) > -1)?_c('span',{staticClass:"widget-selected-num"},[_vm._v(_vm._s(_vm.getWidgetIndex(widget.name)))]):_vm._e()],1)]):_vm._e()}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }