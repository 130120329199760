<template>
  <div>
    <Preview />
  </div>
</template>
<script>
import Preview from '../components/partial/Preview'
export default {
  name: 'PagePreview',
  components: { Preview }
}
</script>
