<template>
  <div>
    <b-btn
      class="m-2"
      :key="`${text}${index}`"
      v-for="(text, index) in sampletexts"
      variant="outline-primary"
      size="sm"
      @click="select(text, true)">
      {{ $t(text) }}
    </b-btn>
  </div>
</template>

<script>
export default {
  name: 'AdsTexts',
  icon: 'life-ring',
  data () {
    return {
      sampletexts: [
        'جشنی برای خانواده و جامعه !',
        'یک نوع متفاوت از فروشگاه هایی که دیده اید',
        'چشمانی زیبا برای لباس هایی زیبا',
        'مکانی برای رسیدن به خواسته هایتان',
        'همیشه پر از خلاقیت !',
        'فروشگاهی ایرانی برای ایرانیان',

        'فقط انجامش بده!',
        'من عاشقشم!',
        'غیر ممکن وجود نداره.',
        'شادترین نقطه روی زمین',
        'من همانی هستم که هستم.',
        'کوچک فکر کنید.',
        'غذای عالی',
        ' حق همیشه و کاملاً با مشتری است.',
        'جایگزینی نیست.',
        'راه حلی برای سیاره هوشمند.',
        'باورش کن.',
        'بزرگ فکر کن.',
        'معجزه دانش.',
        'به مسیرت ادامه بده، ادامه بده، ادامه بده …',
        'در دنیای خود زندگی کنید، در دنیای ما بازی کنید.',
        'آن چیزی که ما اعتقاد داریم را ببینید.',
        'در قلب تصویر',
        'لحظاتت را به اشتراک بذار، زندگی ات را به اشتراک بذار.',
        'وقتی که هیچ فردایی نیست.',
        'پول بیشتری ذخیره کنید، زندگی بهتری داشته باشید.',
        'برای افرادی که تغییر را رقم خواهند زد.',
        'قدرت، زیبایی و روح.',
        'درب قوطی را باز کن تا سرحال شی.',
        'در دهانتان آب می شود، نه در دستانتان.',
        'حالا صدامو می‌شنوی؟ خوبه.',
        'دید شما آینده ی شماست.',
        'بین عشق و دیوانگی، وسواس است.',
        'بیشتر از اکنون باشید.',
        'موفقیت، یک بازی ذهنی است.',
        'تخیل در کار',
        'بزرگترین تراژدی بی تفاوتی است.',
        'به انگشتانتان اجازه بدهید، راه بروند.',
        'نوآوری',
        'ارتباط دادن مردم',
        'الماس همیشگی است.',
        'قهوه در بهترین حالت آن.',
        'حسرت همسایه ها، غرور مالک.',
        'چون من ارزشش را دارم.',
        'چون شما ارزشش را دارید.',
        'به شیوه خودتان درست کنید.',
        'ما چیزهای خوبی در زندگی تان به ارمغان می‌آوریم.',
        'بانک محلی جهانی.',
        'آماده شوید تا به بانوی زیبایی تبدیل شوید.',
        'میکروب ها را زنده نمی‌گذارد.'
      ]
    }
  },
  props: {
    value: {
      type: [Object, Boolean, Array, String],
      required: false
    }
  },
  methods: {
    select (text, hide = false) {
      this.$emit('input', {
        ...this.value,
        text: text
      })
      if (hide) {
        this.$emit('done')
      }
    }
  }
}
</script>
