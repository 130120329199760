export default {
  title: 'Header',
  group: 'header',
  landspace: true,
  widgets: [
    {
      name: 'Header_1',
      height: 46
    },
    {
      name: 'Header_2',
      height: 46
    },
    {
      name: 'Header_3',
      height: 46
    },
    {
      name: 'Header_4',
      height: 46
    },
    {
      name: 'Header_5',
      height: 46
    },
    {
      name: 'Header_6',
      height: 46
    },
    {
      name: 'Header_7',
      height: 46
    },
    {
      name: 'Header_8',
      height: 46
    },
    {
      name: 'Header_9',
      height: 46
    },
    {
      name: 'header_10',
      height: 46
    },
    {
      name: 'header_11',
      height: 46
    },
    {
      name: 'header_12',
      height: 46
    },
    {
      name: 'header_13',
      height: 46
    },
    {
      name: 'header_14',
      height: 46
    }
  ]
}
