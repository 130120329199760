export default {
  language: 'fa',
  'export': 'Export',
  'brand_name': 'Landingo',
  'import': 'Import',
  'show_header': 'Show header',
  'preview_mode': 'Preview Mode',
  'add_widget': 'Add widget, Have fun!',
  'delete': 'Delete',
  'load_url': 'Load Url',
  'toolbox': {
    'iframe_setting': 'Iframe',
    'slider': 'Slider',
    'animate': 'Animate',
    'image_picker': 'Image Picker',
    'icon_picker': 'Icon Picker',
    'border_radius': 'Border radius',
    'widget_selector': 'Widgets',
    'edit': 'Edit',
    'preview': 'Preview',
    'delete': 'Delete',
    'full_width': 'Toggle Full Width',
    'move_up': 'Move Up',
    'move_down': 'Move Down',
    'drag': 'Drag',
    'bg_settings': 'Background setting',
    'undo': 'Undo',
    'redo': 'Redo',
    'mobile': 'Mobile View',
    'tablet': 'Tablet View',
    'desktop': 'Desktop View',
    'line-through': 'Line Through',
    // 'strikethrough' : 'Strikethrough',
    'link': 'Link',
    'alignment': 'Alignment',
    'line-space': 'Line Space',
    'x-large': 'x-Large',
    'large': 'Large',
    'Medium': 'Medium',
    'small': 'Small',
    'x-small': 'x-Small',
    'xx-small': 'xx-Small',
    'indent': 'Indent',
    'bold': 'Bold',
    'underline': 'Underline',
    'font-color': 'Font Color',
    'font-size': 'Font Size',
    'font-family': 'Font Family',
    'bg-color': 'Background Color',
    'border-width': 'Border Width',
    'border-style': 'Border Style',
    'border-color': 'Border Color',
    'input-file': 'Input File',
    'opacity': 'Opacity',
    'close': 'Close',
    'settings': 'Setting',
    'padding': 'Padding',
    'padding_x': 'Padding Horizontal',
    'padding_y': 'Padding Vertical',
    'margin_x': 'Margin Horizontal',
    'margin_y': 'Margin Vertical',
    'margin': 'Margin',
    'height': 'Height',
    'width': 'Width',
    'text': 'Text',
    'border': 'Border',
    'general': 'General',
    'icon': 'Icon',
    'image': 'Image',
    'direction': 'Direction',
    'background_postion_x': 'Horizontal',
    'background_postion_y': 'Vertical',
    'background': 'Background',
    'background_color': 'Background Color',
    'background_size': 'Background Size',
    'background_repeat': 'Background Repeat',
    'background_position': 'Background Position',
    'background_image': 'Background Image',
    'cover': 'Cover',
    'contain': 'Contain',
    'auto': 'Auto',
    'repeat': 'repeat',
    'no-repeat': 'no-repeat',
    'repeat-x': 'repeat-x',
    'repeat-y': 'repeat-y'
  },
  'slider': {
    'mobileFirst': 'Mobile First',
    'autoplay': 'Autoplay',
    'dots': 'Dots',
    'slidesToShow': 'Slides To Show',
    'slider.arrows': 'Arrows',
    'accessibility': 'Accessibility',
    'touchMove': 'Touch move',
    'swipeToSlide': 'Swipe to slide',
    'draggable': 'Draggable',
    'vertical': 'Vertical',
    'lazyLoad': 'Lazy load',
    'infinite': 'Infinite',
    'fade': 'Fade',
    'rows': 'Rows',
    'slidesToScroll': 'Slides to scroll',
    'speed': 'Speed',
    'autoplay_speed': 'Autoplay speed',
    'arrows': 'Arrows'
  },
  'modal': {
    'font_awesome': 'Font Awesome',
    'pick_link': 'Pick link',
    'ok': 'Ok',
    'submit': 'Ok',
    'cancel': 'Cancel',
    'upload': 'Upload',
    'drop_image': 'Drop Image here',
    'or': 'OR',
    'choose_your_image': 'Choose your image',
    'random_image': 'Random Image',
    'drop_maize_json': 'Drop json here',
    'choose_file': 'Choose file',
    'link': {
      'title': 'Insert your link:'
    }
  },
  'animate': {
    'name': 'Name',
    'easing': 'Easing',
    'delay': 'Delay',
    'duration': 'Duration',
    'mirror': 'Mirror',
    'once': 'Once'
  },
  'link': 'Link',
  'text': 'Text',
  'icon': 'Icon',
  'map': 'Map',
  'settings': {
    'settings': 'Setting',
    'favicon': 'Favicon',
    'favicon_placeholder': 'Favicon',
    'favicon-placeholder-drag': 'Drop here',
    'image-al-placeholder': 'Image Alt',
    'favicon-placeholder': 'Favicon',
    'contact-address': 'Address',
    'contact-phone': 'Phone',
    'contact-mail': 'Email',
    'contact-fax': 'Fax',
    'contact-phone-placeholder': 'Phone Number',
    'contact-mail-placeholder': 'Email',
    'contact-fax-placeholder': 'Fax',
    'contact-address-placeholder': 'Address',
    'ga': 'Google Analytics',
    'hotjar': 'Hotjar',
    'ga-placeholder': 'Import Your Google Analytics Script',
    'hotjar-placeholder': 'Import Your Hotjar Script',
    'contact-info': 'Contact Info',
    'monitoring': 'Monitoring',
    'url': 'URL',
    'page_sections': 'Link To Page Sections',
    'name': 'Name',
    'name-placeholder': 'ex: Maize',
    'title': 'Title',
    'title-placeholder': 'Maize',
    'secureURL': 'Secure URL',
    'secureURL-placeholder': 'ex: https://maize.com',
    'language': 'Language',
    'language-placeholder': 'ex: en',
    'baseURL': 'Base URL',
    'baseURL-placeholder': 'ex: https://maize.com/static',
    'canonical': 'Canonical',
    'canonical-placeholder': 'ex: https://maize.com',
    'image': 'Image',
    'image-placeholder': 'ex: https://maize.com/logo.png',
    'imageWidth': 'Image Width',
    'imageWidth-placeholder': 'ex: 120',
    'imageHeight': 'Image Height',
    'imageHeight-placeholder': 'ex: 120',
    'imageType': 'Image Type',
    'imageType-placeholder': 'ex: jpeg',
    'nextURL': 'Next URL',
    'nextURL-placeholder': 'ex: https://maize.com/action.html',
    'color': 'Color',
    'facebookAppID': 'Facebook App ID',
    'facebookAppID-placeholder': 'ex: 3927246697338877',
    'twitterAccount': 'Twitter Account',
    'twitterAccount-placeholder': 'ex: @maize',
    'twitterCard': 'Twitter Card',
    'twitterCard-placeholder': 'ex: summary',
    'description': 'Description',
    'description-placeholder': 'ex: An awesome landing page',
    'type': 'Type',
    'type-placeholder': 'ex: Organization',
    'meta': 'Meta Tags',
    'header': 'Site Settings',
    'slider': 'Slider settings'
  },
  'messages': {
    'drag_image_here': 'Drag Your Image Here! ',
    'iframe_select_url': 'Please set iframe URL !',
    'google_map_iframe_extract': 'Google map embed iframe source will be extract from <iframe src=".... />'
  },
  'hello_from_modal': 'Hello from modal',
  'monospace': 'monospace',
  'serif': 'serif',
  'fantasy': 'fantasy',
  'Roboto': 'Roboto',
  'Open Sans': 'Open Sans',
  'Lato': 'Lato',
  'none': 'none',
  'solid': 'solid',
  'dashed': 'dashed',
  'double': 'double',
  'dotted': 'dotted',
  'choose_pic': 'Choose your picture:',
  'choose_video': 'Choose your video:',
  'choose_widget': 'Choose your widget:',
  'loading': 'loading...',
  'bootstrap-vue': 'Bootstrap Vue',
  'maize': 'Maize',
  'insert_link': 'Insert your link',
  'tamasha': 'Tamasha',
  'search_video_here': 'Search video here',
  'iframe': 'iframe',
  'extensionloader': 'Extension loader',
  'Slider': 'Slider',
  'AOS': 'AOS',
  'mirror': 'Mirror',
  'once': 'Once',
  'Clinet_Side_Uploader': 'Clinet Side Uploader',
  'Picsum_Samples': 'Picsum Samples',
  'Lorem': 'Lorem',
  'Texty': 'Texty',
  'Editor': 'Editor'
}
