export default {
  language: 'en',
  'export': 'ذخیره',
  'import': 'بارگذاری',
  'brand_name': 'لندینگو',
  'show_header': 'نمایش هدر',
  'preview_mode': 'نسخه نمایش',
  'add_widget': 'افزودن ویجت',
  'delete': 'حذف',
  'load_url': 'آدرس',
  'widgets': {
    'Header': 'سربرگ',
    'Call to Action': 'کال تو اکشن',
    'CallToAction': 'کال تو اکشن',
    'Feature': 'ویژگی',
    'Content': 'محتوا',
    'Contact': 'تماس با ما',
    'Footer': 'پاورقی',
    'Kitchen': 'Kitchen',
    'Advanced': 'پیشرفته',
    'Iframe': 'فریم',
    'Slider': 'اسلایدر'
  },
  'messages': {
    'pick_from_templates_success': 'نسخه از پیش طراحی شده ساخته شد!',
    'icant_pick_from_templates': 'شما کار ناتمام دارید! به همین دلیل نمی توانید از صفحات از پیش طراحی شده استفاده کنید!',
    'pick_from_templates': 'می توانید با انتخاب از صفحات پیش ساخته، حتی سریع تر پیش بروید!',
    'start_with_widgets': 'ویجت های دلخواه خود را اضافه کنید!',
    'preview_no_widget': 'بدون انتخاب ویجت چیزی برای نمایش وجود ندارد!',
    'ready_to_import': 'فایل شما به درستی بارگزاری شد!',
    'iframe_select_url': 'پیوند خود را انتخاب کنید',
    'export_model_header': 'دریافت خروجی به صورت فایل زیپ',
    'export_model_lead': '',
    'export_model_file_size': 'حجم فایل خروجی'
  },

  'toolbox': {
    'iframe_setting': 'آدرس فریم',
    'background_position_x': 'موقعیت افقی',
    'background_position_y': 'موقعیت عمودی',
    'background_image': 'تصویر پس زمینه',
    'background_position': 'موقعیت تصویر پس زمینه',
    'background_size': 'اندازه تصویر پس زمینه',
    'background_repeat': 'تکرار تصویر پس زمینه',
    'padding_x': 'حاشیه عمودی',
    'padding_y': 'حاشیه افقی',
    'margin_x': 'فاصله عمودی',
    'margin_y': 'فاصله افقی',
    'name': 'نام',
    'text': 'متن',
    'border': 'خط',
    'general': 'دیگر',
    'animate': 'انیمیشن',
    'background': 'زمینه',
    'widget_selector': 'انتخاب ویجت',
    'preview': 'نمایش',
    'image': 'تصویر',
    'image_picker': 'انتخاب تصویر',
    'edit': 'ویرایش',
    'delete': 'حذف',
    'full_width': 'تمام عرض',
    'move_up': 'ببر بالا',
    'move_down': 'ببر پایین',
    'drag': 'تکون بده',
    'undo': 'به عقب',
    'redo': 'به جلو',
    'mobile': 'موبایل',
    'tablet': 'تبلت',
    'desktop': 'دسکتاپ',
    'line-through': 'خط خورده',
    'link': 'پیوند',
    'alignment': 'تراز',
    'line-space': 'فاصله خطوط',
    'x-large': 'بزرگ تر',
    'large': 'بزرگ',
    'Medium': 'متوسط',
    'small': 'کوچک',
    'x-small': 'کوچک تر',
    'xx-small': 'خیلی کوچک تر',
    'indent': 'تورفتگی ',
    'bold': 'درشت',
    'underline': 'زیرخط دار',
    'font-color': 'رنگ متن',
    'font-size': 'اندازه متن',
    'font-family': 'فونت',
    'bg-color': 'رنگ پس زمینه',
    'border-width': 'اندازه لبه ها',
    'border-style': 'مدل لبه ها',
    'border-color': 'رنگ لبه ها',
    'input-file': 'بارگذاری فایل',
    'opacity': 'وضوح',
    'close': 'بستن',
    'bg_settings': 'تنظیمات پس زمینه',
    'settings': 'تنظیمات',
    'padding': 'حاشیه داخلی',
    'margin': 'حاشیه',
    'height': 'ارتفاع',
    'width': 'عرض',
    'direction': 'جهت',
    'border_radius': 'انحنای گوشه ها',
    'iframe': 'فریم',
    'slider': 'اسلایدر'
  },
  'extension': {
    'AdsTexts': 'متون تبلیغاتی',
    'Link': 'پیوند',
    'Clinet_Side_Uploader': 'بارگذاری تصویر',
    'FontAwesomePicker': 'انتخاب آیکون',
    'Picsum_Samples': 'تصاویر پیکسام',
    'StreamLine_Samples': 'تصاویر استریم لاین',
    'AOS': 'انیمیشن در اسکرول',
    'Editor': 'ویرایش  متن دلخواه',
    'Texty': 'متن پیش فرض از تکستی',
    'Lorem': 'متن از سایت لورم ایپسوم'
  },
  'animate': {
    'name': 'نوع انیمیشن',
    'easing': 'شتاب',
    'delay': 'تاخیر',
    'duration': 'مدت زمان',
    'mirror': 'معکوس',
    'once': 'فقط یکبار'
  },
  'button': {
    'back': 'بازگشت'
  },
  'modal': {
    'previews': 'قبلی',
    'back': 'بازگشت',
    'next': 'بعدی',
    'continue': 'ادامه',
    'pick_link': 'انتخاب پیوند',
    'ok': 'تایید',
    'export': 'ذخیره خروجی',
    'submit': 'تایید',
    'cancel': 'انصراف',
    'upload': 'بارگذاری',
    'drop_image': 'تصویر خود را اینجا رها کنید',
    'or': 'یا',
    'choose_your_image': 'انتخاب کنید',
    'random_image': 'تصاویر تصادفی',
    'drop_maize_json': 'کشیدن و رها کردن فایل',
    'choose_file': 'انتخاب فایل',
    'link': {
      'title': 'پیوند خود را وارد کنید:'
    }
  },
  'settings': {
    'exportAddFontIcon': 'افزودن فونت آیکون',
    'exportAddIranSansFont': 'افزودن فونت ایران سنس',
    'exportAddFontIranYekan': 'افزودن فونت ایران یکان',
    'exportCssSingleFile': 'استایل ها به صورت تک فایل',
    'exportJavascriptSingleFile': 'اسکریپت ها به صورت تک فایل',

    'customCode': ' افزودن کد دلخواه',
    'customCss': 'استایل (CSS)',
    'customJavascript': 'جاوااسکریپت (JavaScript)',
    'name': 'نام پروژه (انگلیسی بهتر است)',
    'name-placeholder': 'نام را وارد کنید',
    'color': 'رنگ',
    'favicon': 'نماد',
    'contact-info': 'اطلاعات تماس',
    'monitoring': 'مانیتورینگ',
    'language': 'زبان',
    'secureURL': 'آدرس امن',
    'secureURL-placeholder': 'آدرس امن را وارد کنید',
    'baseURL': 'آدرس مبنا',
    'baseURL-placeholder': 'آدرس مبنا را وارد کنید',
    'settings': 'تنظیمات',
    'header': 'تنظیمات سایت',
    'title': 'عنوان وب سایت',
    'title-placeholder': '',
    'description': 'شرح',
    'description-placeholder': 'توضیحات وب سایت',
    'type': 'نوع',
    'type-placeholder': 'e.g. Splash page',
    'twitter-account': 'حساب توییتر',
    'twitter-placeholder': '@Example',
    'instagram-account': 'حساب اینستاگرام',
    'instagram-placeholder': '@Example',
    'canonical': 'آدرس سایت',
    'canonical-placeholder': 'Canonical',
    'nextURL': 'آدرس بعدی',
    'nextURL-placeholder': 'آدرس بعدی را وارد کنید',
    'image': 'تصویر',
    'image-placeholder': 'تصویر را وارد کنید',
    'imageWidth': 'عرض تصویر',
    'imageWidth-placeholder': 'عرض تصویر را وارد کنید',
    'imageHeight': 'ارتفاع تصویر',
    'imageHeight-placeholder': 'ارتفاع تصویر را وارد کنید',
    'imageType': 'نوع تصویر',
    'imageType-placeholder': 'نوع تصویر را انتخاب کنید',
    'facebookAppID': 'آدرس فیسبوک',
    'facebookAppID-placeholder': 'آدرس فیسبوک را وارد کنید',
    'twitterAccount': 'آدرس توییتر',
    'twitterAccount-placeholder': 'آدرس توییتر را وارد کنید',
    'twitterCard': 'آدرس توییتر کارت',
    'twitterCard-placeholder': 'آدرس توییتر کارت را وارد کنید',
    'contact-address': 'آدرس تماس',
    'contact-address-placeholder': 'آدرس تماس را وارد کنید',
    'contact-mail': 'پست الکترونیکی',
    'contact-mail-placeholder': 'پست الکترونیکی را وارد کنید',
    'contact-phone': 'شماره تماس',
    'contact-phone-placeholder': 'شماره تماس را وارد کنید',
    'contact-fax': 'نمابر',
    'contact-fax-placeholder': 'نمابر خود را وارد کنید',
    'ga': 'گوگل آنالیتیکس',
    'ga-placeholder': 'گوگل آنالیتیکس را وارد کنید',
    'hotjar': 'هاتجر',
    'hotjar-placeholder': 'هاتجر را وارد کنید',
    'logo-url': 'آدرس لوگو',
    'logo-placeholder': 'http://www.example.com',
    'banner-url': 'آدرس بنر',
    'banner-placeholder': 'http://www.example.com',
    'banner-color': 'رنگ بنر',
    'color-placeholder': ':یک رنگ را انتخاب کنید',
    'url': 'آدرس'
  },
  'exportModal': {
    'email_title': 'آدرس ایمیل خود را جهت دریافت فایل وارد کنید.',
    'email_placeholder': 'آدرس ایمیل خود را وارد کنید.',
    'config_title': 'نمونه تنظیمات برای ذخیره فایل خروجی',
    'email_message': 'ما آدرس ایمیل شما را به هیچ عنوان با دیگران به اشتراک نمی گذاریم'
  },
  'hello_from_modal': 'Hello from modal',
  'monospace': 'monospace',
  'serif': 'serif',
  'fantasy': 'fantasy',
  'none': 'none',
  'solid': 'solid',
  'dashed': 'dashed',
  'double': 'double',
  'dotted': 'dotted',
  'choose_pic': ':تصویر خود را انتخاب کنید',
  'choose_video': 'ویدیوی خود را انتخاب کنید',
  'choose_widget': 'ویجت خود را انتخاب کنید',
  'loading': '...بارگذاری',
  'bootstrap-vue': 'یوت استرپ-ویو',
  'maize': 'Maize',
  'insert_link': 'پیوند خود را وارد کنید',
  'tamasha': 'تماشا',
  'search_video_here': 'اینجا فیلم را جستجو کنید'
}
