export default {
  title: 'Feature',
  group: 'feature',
  widgets: [
    {
      name: 'Feature_1'
    },
    {
      name: 'Feature_2'
    },
    {
      name: 'Feature_3'
    },
    {
      name: 'Feature_4'
    },
    {
      name: 'Feature_5'
    },
    {
      name: 'Feature_6'
    },
    {
      name: 'Feature_7'
    },
    {
      name: 'Feature_8'
    },
    {
      name: 'Feature_9'
    },
    {
      name: 'Feature_10'
    },
    {
      name: 'Feature_11'
    },
    {
      name: 'Feature_12'
    },
    {
      name: 'Feature_13'
    },
    {
      name: 'Feature_14'
    },
    {
      name: 'Feature_15'
    },
    {
      name: 'Feature_16'
    },
    {
      name: 'Feature_17'
    },
    {
      name: 'Feature_18'
    },
    {
      name: 'Feature_19'
    },
    {
      name: 'Feature_20'
    },
    {
      name: 'Feature_21'
    },
    {
      name: 'Feature_22'
    },
    {
      name: 'Feature_23'
    },
    {
      name: 'Feature_24'
    },
    {
      name: 'Feature_25'
    },
    {
      name: 'Feature_26'
    },
    {
      name: 'Feature_27'
    }
  ]
}
