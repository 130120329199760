export default {
  title: 'Footer',
  group: 'footer',
  landspace: true,
  widgets: [
    {
      name: 'Footer_1'
    },
    {
      name: 'Footer_2'
    },
    {
      name: 'Footer_3'
    },
    {
      name: 'Footer_4'
    },
    {
      name: 'Footer_5'
    },
    {
      name: 'Footer_6'
    },
    {
      name: 'Footer_7'
    },
    {
      name: 'Footer_8'
    },
    {
      name: 'Footer_9'
    },
    {
      name: 'Footer_10'
    }
  ]
}
