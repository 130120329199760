import Footer1 from './Footer_1'
import Footer2 from './Footer_2'
import Footer3 from './Footer_3'
import Footer4 from './Footer_4'
import Footer5 from './Footer_5'
import Footer6 from './Footer_6'
import Footer7 from './Footer_7'
import Footer8 from './Footer_8'
import Footer9 from './Footer_9'
import Footer10 from './Footer_10'

export default {
  Footer_1: Footer1,
  Footer_2: Footer2,
  Footer_3: Footer3,
  Footer_4: Footer4,
  Footer_5: Footer5,
  Footer_6: Footer6,
  Footer_7: Footer7,
  Footer_8: Footer8,
  Footer_9: Footer9,
  Footer_10: Footer10
}
