<template>
  <div class="p-5">
    <Templates />
  </div>
</template>
<script>
import Templates from '../components/partial/Templates'
export default {
  name: 'PageTemplates',
  components: { Templates }
}
</script>
